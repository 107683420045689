<template>
    <div class="row">
        <slot></slot>
        <div class="col-2 d-flex align-items-end mt-2">
            <button style="min-width: 64px;" @click="handleDateChange"
                class="btn btn-primary btn-block waves-effect waves-float waves-light">{{ btnTitle }}
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TrackerQuerySelector',

    props: {
        btnTitle: {
            default: 'Go'
        },
        inEnoughSpace: {
            default: true
        }
    },

    data: () => ({
        start: '',
        end: ''
    }),

    methods: {

        handleDateChange() {
            this.$emit('onClickGo')
        }
    },

    mounted() {
        this.start = this.$route.query.start,
            this.end = this.$route.query.end
    }
}
</script>
