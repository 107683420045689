<template>
  <div class="modal fade show modal-style" aria-labelledby="addNewCardTitle">
    <div class="modal-dialog modal-dialog-centered modal-lg" ref="modalRef">
      <div class="modal-content">
        <div class="row">
          <div class="col-12">
            <div class="d-flex justify-content-between px-1 my-1 align-item-center">
              <h1 class="mb-0">Project status update</h1>
              <button
                  @click="emit('onClickClose')"
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
              ></button>
            </div>
          </div>
        </div>
        <form @submit.prevent="statusUpdate">
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <table class="table table-bordered">
                  <tr>
                    <th>Project Name:</th>
                    <th>Client Name:</th>
                    <th>Current Status:</th>
                  </tr>
                  <tr>
                    <td>{{ selectedItem.project_full_name }}</td>
                    <td>{{ selectedItem.client_name }}</td>
                    <td class="text-capitalize">{{ selectedItem.status }}</td>
                  </tr>
                </table>
              </div>
            </div>
            <br>
            <hr>
            <div class="row mt-1">
              <div class="col-sm-4">
                <label class="align-middle">Project delivery timeline</label>
                <v-select
                    placeholder="Project delivery timeline"
                    v-model="formData.project_delivery_timeline"
                    :options="projectSatisfactionOptions"
                    label="name"
                    :reduce="name => name.id"
                />
              </div>
              <div class="col-sm-4">
                <label class="align-middle">Client satisfaction</label>
                <v-select
                    placeholder="Client Satisfaction"
                    v-model="formData.client_satisfaction"
                    :options="projectSatisfactionOptions"
                    label="name"
                    :reduce="name => name.id"
                />
              </div>
              <div class="col-sm-4">
                <label class="align-middle">Team excellence</label>
                <v-select
                    placeholder="Team Excellence"
                    v-model="formData.team_excellence"
                    :options="projectSatisfactionOptions"
                    label="name"
                    :reduce="name => name.id"
                />
              </div>
              <div class="col-sm-6 mt-2">
                <label class="align-middle">Project completed date</label>
                <input type="date" v-model="formData.completed_date_time" class="form-control"/>
              </div>
              <div class="col-sm-6 mt-2">
                <label class="align-middle">Comments</label>
                <textarea class="form-control" v-model="formData.comments"/>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-primary" type="submit">Update</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>

import {computed, inject, onMounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import handleProjects from "@/services/modules/procurement/project";
import handleInventory from "@/services/modules/inventory";
import useDate from "@/services/utils/day";

const showError = inject('showError');
const showSuccess = inject('showSuccess');
const {updateProjectStatus} = handleProjects()
const {fetchHome} = handleInventory();
const {currentDate} = useDate();

const props = defineProps({
  onClickClose: Function,
  selectedItem: {
    type: Object,
    default: {}
  }
})
const route = useRoute()
const router = useRouter()
const emit = defineEmits(['onClickClose', 'updateItem']);

function getQuery() {
  return `?company_id=${route.params.companyId}`;
}

const projectSatisfactionStatus = ref({})

const projectSatisfactionOptions = computed(() => {
  let options = [];
  Object.keys(projectSatisfactionStatus.value).forEach(id => {
    options.push({
      id: id,
      name: projectSatisfactionStatus.value[id],
    })
  })
  return options;
})

const formData = ref({
  completed_date_time: currentDate(),
  project_delivery_timeline: null,
  client_satisfaction: null,
  team_excellence: null,
  comments: '',
  status: 'completed',
})

const statusUpdate = () => {
  updateProjectStatus(props.selectedItem.id, getQuery(), formData.value).then(({message, status}) => {
    if (!status) {
      return showError(message)
    }
    emit('onClickClose')
    emit('updateItem', props.selectedItem.id, 'completed')
    showSuccess(message)
  });
}

const getProjectSatisfactionStatus = () => {
  const homeRes = fetchHome();
  homeRes.then(({data}) => {
    if (data) {
      const {project_satisfaction_status} = data;
      projectSatisfactionStatus.value = project_satisfaction_status;
    }
  })
}

onMounted(() => {
  getProjectSatisfactionStatus()
})
</script>

<style scoped>
.modal-style {
  display: block;
  background: #1c1d1c33
}
</style>
